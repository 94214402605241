import backgroundImg from "./assets/bg2.jpeg";
import About from "./pages/About";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div
        data-color="red-blue"
        data-tint="-dark"
        data-clipboard-text="blend-red-blue-dark"
        className="blend-red-blue-dark block bgimage-container"
      >
        <img
          src={backgroundImg}
          className="make-it-fullwidth"
          alt="background"
          aria-hidden="true"
        />

        <main>
          <About />
        </main>
      </div>
    </div>
  );
}

export default App;
